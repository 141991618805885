<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <el-card class="box-card">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                    <el-button type="primary" @click="addother_coin(0)" class="buttons">新增单据</el-button>
                    <!-- <el-button  @click="importData" class="buttons">导入</el-button> -->
                    <el-button  @click="exportData" class="buttons">导出</el-button>
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="类型" >
                        <el-select v-model="req.type" placeholder="请选择" style="width:120px;">
                            <el-option
                                    v-for="item in statusList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.start"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 00:00:00"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.end"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 23:59:59"
                                type="date"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                                placeholder="请输入关键词搜索"
                                prefix-icon="el-icon-search"
                                v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>
                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    
                    <el-table-column label="创建时间"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.create_time }}</template>
                    </el-table-column>

                    <el-table-column label="单据编号"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.number}}</template>
                    </el-table-column>
                    <el-table-column label="收支类型"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.type }}</template>
                    </el-table-column>
                     <el-table-column label="费用类型"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.class }}</template>
                    </el-table-column>                 
                     <el-table-column label="金额（元）"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.amount }}</template>
                    </el-table-column>     

                     <el-table-column label="结算方式"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.account_management_info?scope.row.account_management_info.name:'' }}</template>
                    </el-table-column>     

                     <el-table-column label="备注"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.remarks }}</template>
                    </el-table-column>     

                     <el-table-column label="附件"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.enclosure.length }}个</template>
                    </el-table-column>     

                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row.id)" >
                                编辑
                            </el-button>
                            <el-button type="text" size="small" @click="remove(scope.row.id)" >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </div>
        <!-- 页码 -->
        <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>
        
        <el-dialog
            :modal-append-to-body="false"
            title="导出数据"
            :visible.sync="exportExcelFlag"
            v-if="exportExcelFlag"
            width="60%"
            center>
                <div style="height:50px;width:100%;text-align:center;">
                    <i class="el-icon-loading" v-if="exportStatus===1" style="font-size:50px;color:#000;"></i>
                    <i class="el-icon-success" v-if="exportStatus===2" style="font-size:50px;color:green;"></i>
                </div>
                <div v-if="exportStatus===1" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据正在导出...稍后会自动下载，请留意下载工具
                </div>
                <div v-if="exportStatus===2" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据已导出完毕，本窗口即将关闭
                </div>
        </el-dialog>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分类管理"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="800px"
        center>
            <createShare  :id="currentId" @fleshList="fleshList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import methods from "./method";
import datas from "./objectData";
import tools from "../../../extends/tools";

export default {
    props:{

    },
    inject: ['selectSide'], 
    data(){
        return datas;
    },
    components:{
        createShare,shareListFlag
    },
    methods:methods,
    created(){
        //数据源
        apis.other_coinSource().then(res=>{
            if(tools.msg(res,this)){
                this.statusList = res.data.class;
            }
        }).catch(err=>{
            tools.err(err,this);
        });

        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
.all_div{
    border-width: 0px;
    width: 96%;
    height: 140px;
    background: inherit;
    background-color: rgba(0, 110, 255, 0.0980392156862745);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 110, 255, 1);
    border-radius: 4px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #006EFF;
    text-align: center;
}
</style>